<template>
  <div class="order-item">
    <div>
      <h3>
        {{ item.unity.number }} |
        {{ item.unity.tipology ? item.unity.tipology.name : "" }}
        {{ item.area_enterprise }}
      </h3>
      <p>{{ item.unity.tipology ? item.unity.tipology.description : "" }}</p>
    </div>
    <div class="order-actions">
      <div>
        <span>STATUS</span>
        <b-button
          :class="item.unity.order ? item.unity.order.status : 'NO-CUSTOM'"
          disabled
          >{{
            getStatus(item.unity.order ? item.unity.order.status : "NO-CUSTOM")
          }}</b-button
        >
        <span class="update_date" v-if="item.unity.order">{{
          item.unity.order.updated_at | date
        }}</span>
      </div>
      <div>
        <span>DETALHES DA PERSONALIZAÇÃO</span>

        <b-button
          @click="openCustom(item.unity.order ? item.unity.order.id : '')"
          :disabled="!item.unity.order ? true : false"
          :class="{ NEW: !item.unity.order }"
          >VER PEDIDO</b-button
        >
      </div>
      <div>
        <span style="color: transparent">-</span>
        <b-button
          :disabled="item.unity.order ? true : false"
          :class="{ NEW: item.unity.order }"
          @click="$router.push('/kit/bem-vindo')"
          >PERSONALIZAR</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Order",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY - H:MM");
    },
  },
  methods: {
    openCustom(id) {
      this.$router.push(`/kit/minha-personalizacao/${id}`);
    },
    getStatus(item) {
      const status = {
        NEW: "A REALIZAR",
        IN_PROGRESS: "EM ANDAMENTO",
        FINISHED: "CONCLUÍDO",
        "NO-CUSTOM": "NÃO PERSONALIZADO",
      };
      return status[item];
    },
  },
};
</script>

<style scoped>
.order-item {
  background: rgba(244, 244, 246, 0.5);
  padding: 20px 30px;
  border-radius: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.order-item h3 {
  color: #2f2f2f;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.order-item p {
  margin: 0;
  color: rgba(47, 47, 47, 0.7);
  font-size: 14px;
  margin-top: 5px;
}
.order-item .order-actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
}
.order-item .order-actions span {
  font-size: 12px;
  color: rgba(47, 47, 47, 0.5);
  margin: 0;
  display: block;
}
.order-item .order-actions span.update_date {
  color: rgba(47, 47, 47, 0.7);
  font-size: 13px;
}

.order-item button {
  max-width: 100%;
  border-radius: 12px;
  height: 55px;
  padding: 0 30px;
  margin-top: 12px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 220px;
  margin: 7px 0;
  background: var(--color);
}
.order-item button:hover {
  filter: brightness(0.95);
}
.order-item button.NEW,
.order-item button.NO-CUSTOM {
  background: #c9c9c9;
  color: #fff;
  opacity: 1;
}
.order-item button.IN_PROGRESS {
  background: #ffffff;
  color: #6c6c6c;
  opacity: 1;
}
.order-item button.FINISHED {
  background: #68d18d;
  color: #fff;
  opacity: 1;
}
@media screen and (max-width: 768px) {
  .order-item {
    padding: 20px;
    border-radius: 8px;
    grid-template-columns: 1fr;
  }
  .order-item h3 {
    font-size: 14px;
  }
  .order-item p {
    font-size: 13px;
  }
  .order-item .order-actions {
    gap: 5px;
    /* flex-direction: column; */
    display: block;
    width: 100%;
  }
  .order-item .order-actions span {
    font-size: 11px;
    margin-top: 7px;
  }
  .order-item .order-actions span.update_date {
    font-size: 12px;
  }

  .order-item button {
    border-radius: 8px;
    height: 45px;
    padding: 0 20px;
    margin-top: 0px;
    margin: 5px;
    width: 100%;
    font-size: 13px;
    flex: 1;
  }
  .order-item button:hover {
    filter: brightness(0.95);
  }
}
</style>