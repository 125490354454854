<template>
  <div class="container-fluid px-0 py-2" style="margin-bottom: 100px">
    <div class="topo-itens">
      <div>
        <h3 style="font-weight: 600">Minhas personalizações</h3>
      </div>
    </div>
    <div class="col-12">
      <div class="p-dash-cliente-master">
        <div class="col-12 col-md-12 mx-auto">
          <div class="row">
            <div
              class="col-12 cancela-col-padding"
              v-show="!loading"
              data-anima="bottom"
            >
              <div
                class="custom"
                v-for="el in enterprises_unities_orders"
                :key="el.id"
              >
                <img :src="el.image" :alt="el.name" class="logo-enterprise" />
                <div class="list-orders" v-if="el.unities_itens.length > 0">
                  <OrderItem
                    v-for="item in el.unities_itens"
                    :key="item.id"
                    :item="item"
                    class="item-order"
                  />
                </div>
                <div
                  class="col-12 text-center p-4"
                  v-if="enterprises_unities_orders.length == 0"
                >
                  Nenhuma personalização encontrada.
                </div>
              </div>
              <div class="col-12 mt-5">
                <BasePagination
                  v-if="enterprises_unities_orders.length > 0"
                  :totalPages="pagination.totalPages"
                  :activePage="pagination.currentPage"
                  @to-page="toPage"
                  @per-page="perPage"
                />
              </div>
            </div>
            <div style="display: flex" data-anima="top" v-if="loading">
              <div
                class="col-12 cancela-col-padding"
                style="text-align: center"
              >
                <div class="loading">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderItem from "@/components/Kit/Order.vue";
import UserService from "@/services/resources/UserService";
const serviceUser = UserService.build();

import OrderService from "@/services/resources/OrderService";
const serviceOrder = OrderService.build();

export default {
  data() {
    return {
      loading: false,
      unities: [],
      enterprises_unities_orders: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
        itens: 0,
      },
    };
  },
  components: {
    OrderItem,
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },

  methods: {
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchOrders();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchOrders();
    },
    fetchOrders() {
      this.loading = true;
      this.enterprises_unities_orders = [];
      let data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        url: `/user/unities/${this.user.id}`,
      };
      serviceOrder
        .search(data)
        .then((resp) => {
          resp.data.forEach((item) => {
            const _has = this.enterprises_unities_orders.find(
              (e) => e.name == item.enterprise.name
            );
            if (_has) {
              _has.unities_itens.push({
                area_enterprise: item.enterprise.area,
                unity: item.unity,
              });
            } else {
              this.enterprises_unities_orders.push({
                ...item.enterprise,
                unities_itens: [
                  {
                    area_enterprise: item.enterprise.area,
                    unity: item.unity,
                  },
                ],
              });
            }
          });
          this.pagination.totalPages = resp.last_page;
          this.pagination.itens = resp.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    this.fetchOrders();
  },
};
</script>

<style>
body {
  background-color: #fff !important;
}
</style>
<style scoped>
.container-fluid {
  min-height: 80vh;
}
.topo-itens {
  background: rgba(244, 244, 246, 0.5);
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #f4f4f6;
}
.topo-itens a,
.topo-itens h3 {
  margin: 0;
  color: #797979;
}
.loading {
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom {
  padding: 40px 0;
}
.item-order + .item-order {
  margin-top: 20px;
}
.logo-enterprise {
  max-width: 200px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .cancela-col-padding {
    padding: 0 !important;
  }
  .topo-itens {
    padding: 20px;
    gap: 15px;
  }
  .topo-itens h3 {
    font-size: 14px;
  }
  .custom {
    padding: 20px 0;
  }
  .item-order + .item-order {
    margin-top: 10px;
  }
  .custom h2 {
    font-weight: 600;
    font-size: 16px;
  }
}
</style>